<template>
    <div>
        <van-nav-bar title="专业审核" left-text="返回" @click-left="back" fixed left-arrow/>
        
        <div class="login_form">
            <van-skeleton  v-for="(item,index) in report" :key="index" title avatar :row="3" :loading="false">
                <van-panel class="one_report" :title="item.name" status="待专业审核">
                    <template #header>
                        <span class="report_name" v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText></span>
                        <span class="report_name" v-else>{{item.name}}</span>
                    </template>
                    <div class="form_text">
                        <span style="margin-left:5px;">工作日期: {{item.dateStr}}</span>
                        <span style="float:right;">
                            <i v-if="parseFloat(item.reportTime)>parseFloat(item.calculateTime)+0.5" style="color:red;margin-right:8px;" class="fa fa-exclamation-triangle"></i>
                            总填报：
                            <span :style="parseFloat(item.reportTime)>parseFloat(item.calculateTime)+0.5?'color:red':''">{{item.reportTime}}h</span>
                        </span>
                        <!-- <span>系统智能统计：{{item.calculateTime}}h</span> -->
                    </div>
                    <div v-for="(item1,index1) in item.data" :key="index1" class="one_report_data">
                        <div class="project_title">项目：{{item1.project}}</div>
                        <div class="project_title" v-if="item1.projectCode != null">项目：{{item1.projectCode}}</div>
                        <div class="project_title" v-if="item1.subProjectName != null" >子项目：{{item1.subProjectName}}</div>
                        <div class="project_title" v-if="user.company.packageProject==1&&item1.groupName != null" >任务分组：{{item1.groupName}}</div>
                        <div class="project_title" v-if="user.company.packageProject==1&&item1.stage != '-'" >投入阶段：{{item1.stage}}</div>
                        <!--自定义维度 -->
                        <div class="project_title" v-if="user.timeType.customDegreeActive == 1" >{{user.timeType.customDegreeName}}：{{item1.degreeName}}</div>
                        <div class="project_title" v-if="user.timeType.customDataActive == 1" >{{user.timeType.customDataName}}：{{item1.customData}}</div>
                        <div class="project_title" v-if="user.timeType.customTextActive == 1" >{{user.timeType.customTextName}}：{{item1.customText}}</div>
                        <div class="project_title" v-if="user.company.packageEngineering == 1">
                            专业进度：
                            <span style="margin-right:10px;" v-for="progressItem in item1.professionProgressList" :key="progressItem.id">{{progressItem.professionName}}({{progressItem.progress}}%) 
                            </span>
                        </div>
                        
                        <div class="project_title" v-if="item1.taskId != null" >任务：{{item1.taskName}}</div>
                        <!--根据类型选择使用的模板 -->
                        <div v-if="item1.multiWorktime == 0">
                            <div class="project_time">时长：
                                <span v-if="item1.reportTimeType == 0" style="margin-right:10px;">{{fullDayTxt[item1.timeType]}}</span>
                                <span v-if="item1.reportTimeType == 2" style="margin-right:10px;">{{item1.startTime+'-'+item1.endTime}}</span>{{item1.time.toFixed(1)}}h
                                <div class="button" v-if="item1.isOvertime == 1">加班<span v-if="item1.overtimeHours">{{item1.overtimeHours.toFixed(1)}}h</span></div>
                            </div>
                            <div class="project_content">事项：<span v-html="item1.content"></span></div>
                            
                        </div>
                        <div v-if="item1.multiWorktime == 1">
                            <div>
                                项目时长：<span style="margin-right:10px;">{{item1.time.toFixed(1)}}h</span>
                                <div class="button" v-if="item1.isOvertime == 1">加班<span v-if="item1.overtimeHours">{{item1.overtimeHours.toFixed(1)}}h</span></div>
                            </div>
                            <div style="position:relative;border:#ccc 0.5px solid;padding:3px;margin:5px 0px;" v-for="(timeItem, index) in item1.worktimeList" :key="index" >
                                <div class="project_time">时长：
                                    <!-- <span v-if="timeItem.reportTimeType == 0" style="margin-right:10px;">{{fullDayTxt[item1.timeType]}}</span> -->
                                    <span style="margin-right:10px;">{{timeItem.startTime+'-'+timeItem.endTime}}</span>{{timeItem.time.toFixed(1)}}h
                                </div>
                                <div class="project_content">事项：<span v-html="timeItem.content"></span></div>
                            </div>
                        </div>
                        <div style="padding:5px;text-align:center;" v-if="item1.pics != null && item1.pics.length > 0">
                            <span v-for="(p, index) in item1.pics"  :key="p" style="margin-right:10px;">
                            <img  :src="p" style="width:80px; height:80px;" @click="showLargeImg(item1.pics, index)"/>
                            </span>
                        </div>
                    </div>
                    <div class="form_btn" slot="footer">
                        <van-button size="small" type="info" @click="approve(item.id, item)">通过</van-button>
                        <van-button size="small" type="danger" @click="deny(item.id,1, item)">驳回</van-button>
                    </div>
                    <van-popup v-model="imgShow" position="bottom" closeable >
                        <van-swipe class="my-swipe"  indicator-color="white">
                        <van-swipe-item v-for="(picItem, index) in tmpPics" :key="index">
                            <img :src="picItem" style="width:100%;" />
                        </van-swipe-item>
                        </van-swipe>
                    </van-popup>
                </van-panel>
            </van-skeleton>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                fullDayTxt:['全天','上午','下午'],
                tmpPics:[],
                imgShow: false,
                user: JSON.parse(localStorage.userInfo),
                minDate: new Date(2010, 0, 1),
                maxDate: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()),
                currentDate: new Date(),
                nowTime: this.format(new Date(new Date()),"yyyy-MM-dd"),
                showPicker: false,
                report: [],
            };
        },
        created() {
        },
        methods: {
            showLargeImg(item, index) {
                this.imgShow = true;
                this.tmpPics = item;
            },
            // 返回
            back() {
                history.back();
            },

             // 时间转换
            format(date, pattern) {
                pattern = pattern || "yyyy-MM-dd";
                var _this = this;
                return pattern.replace(/([yMdhsm])(\1*)/g, function ($0) {
                    switch ($0.charAt(0)) {
                        case 'y': return _this.padding(date.getFullYear(), $0.length);
                        case 'M': return _this.padding(date.getMonth() + 1, $0.length);
                        case 'd': return _this.padding(date.getDate(), $0.length);
                        case 'w': return date.getDay() + 1;
                        case 'h': return _this.padding(date.getHours(), $0.length);
                        case 'm': return _this.padding(date.getMinutes(), $0.length);
                        case 's': return _this.padding(date.getSeconds(), $0.length);
                    }
                });
            },

            padding(s, len) {
                var len = len - (s + '').length;
                for (var i = 0; i < len; i++) { s = '0' + s; }
                return s;
            },

            // 改变时间
            changeTime(time) {
                this.nowTime = this.format(new Date(time),"yyyy-MM-dd");
                this.currentDate = time;
                this.showPicker = false;
                this.getReport();
            },

            // 获取待专业审核的日报列表
            getReport() {
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                this.$axios.post("/report/listByStateProfession", {state:0})
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        this.report = res.data;
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('获取失败:'+res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
            },

            approve(id, item) {
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                var ids = '';
                var data = item.data;
                data.forEach(element => {
                    if (element.id != null && element.id != '') {
                        ids +=(element.id+',');
                    }
                });
                this.$axios.post("/report/approve", {id: id , date: this.nowTime, reportIds: ids})
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        this.$toast.success('审核成功');
                        this.getReport();
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('审核失败');
                    }
                }).catch(err=> {this.$toast.clear();});
            },

            deny(id,i, item) {
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                var ids = '';
                var data = item.data;
                data.forEach(element => {
                    if (element.id != null && element.id != '') {
                        ids +=(element.id+',');
                    }
                });
                this.$axios.post("/report/deny", {id: id , date: this.nowTime, reportIds: ids})
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        this.$toast.success(i==0?'驳回成功':'撤销成功');
                        this.getReport();
                    } else {
                        this.$toast.clear();
                        this.$toast.fail(i==0?'驳回失败':'撤销失败');
                    }
                }).catch(err=> {this.$toast.clear();});
            }
        },

        mounted() {
            this.getReport();
        }
    };
</script>

<style lang="less" scoped>
    .login_form {
        margin-top: 46px;
    }

    .one_report {
        margin-bottom: 15px;
        font-size:14px;
        .report_name{
            padding-top: .26667rem;
            margin-left: 0.32rem;
            padding-left: 5px;
            display: block;
        }
    }

    .form_text {
        margin: 10px 0 10px;
        padding: 0 12px;
    }
    
    .form_btn {
        text-align: right;
    }

    .form_btn button {
        margin-left: 10px;
    }

    .one_report_data {
        margin-bottom: 10px;
        padding: 0 22px;
        div {
            line-height: 30px;
        }
    }
</style>
<style lang="less">
    .van-nav-bar .van-icon , .van-nav-bar__text {
        color: #20a0ff;
    }
    // 显示加班样式
    .one_span {
        width: 40px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        box-sizing: border-box;
        display: inline-block;
        border: 1px solid #fde2e2;
        background: #fef0f0;
        color: #f56c6c;
        font-size: 12px;
        border-radius: 5px;
        // margin-left: 40px;
        float: right;
    }
</style>
